<template>
  <div class="modal" style="display:block;">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modalLeft">
              <div class="mdImg">
                  <img src="@/assets/images/one.png">
              </div>
              <div class="mdImge">
                  <img src="@/assets/images/two.png">
              </div>
              <div class="mdImges">
                  <img src="@/assets/images/three.png">
              </div>
            <h2>Get in touch</h2>
              <div class="svgText">
                  <div class="modalsvg">
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" fill="#D33E32" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
                  </div>
                  <div class="modalText">
                      <h6>7901 4th ST N STE 8022 St. Petersburg, FL 33702</h6>
                  </div>
              </div>
              <div class="svgText">
                      <div class="modalsvg">
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" fill="#D33E32" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0  1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/></svg>
                      </div>
                      <div class="modalText">
                          <h6>+1(727) 300-6244</h6>
                      </div>
              </div>
              <div class="svgText">
                <div class="modalsvg">
                  <!-- <a href="mailto:bigwavedevelopment"> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" fill="#D33E32" class="bi bi-skype" viewBox="0 0 16 16">
                      <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.423 7.423 0 0 1 6.02 2.118 7.372 7.372 0 0 1 2.167 5.215c0 .344-.024.687-.072 1.026a4.662 4.662 0 0 1 .6 2.281 4.645 4.645 0 0 1-1.37 3.294A4.673 4.673 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.423 7.423 0 0 1-6.114-2.107A7.374 7.374 0 0 1 .529 8.035c0-.363.026-.724.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7.07 7.07 0 0 0 1.257.653c.492.205.873.38 1.145.523.229.112.437.264.615.448.135.142.21.331.21.528a.872.872 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.618 2.618 0 0 1-1.048-.206 11.44 11.44 0 0 1-.532-.253 1.284 1.284 0 0 0-.587-.15.717.717 0 0 0-.501.176.63.63 0 0 0-.195.491.796.796 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.113 5.113 0 0 0 2.212.419 4.554 4.554 0 0 0 1.624-.265 2.296 2.296 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.09 2.09 0 0 0-.279-1.101 2.53 2.53 0 0 0-.772-.792A7.198 7.198 0 0 0 8.486 7.3a1.05 1.05 0 0 0-.145-.058 18.182 18.182 0 0 1-1.013-.447 1.827 1.827 0 0 1-.54-.387.727.727 0 0 1-.2-.508.805.805 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096.274.079.542.177.802.293.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.69.69 0 0 0 .18-.496.717.717 0 0 0-.17-.476 1.374 1.374 0 0 0-.556-.354 3.69 3.69 0 0 0-.708-.183 5.963 5.963 0 0 0-1.022-.078 4.53 4.53 0 0 0-1.536.258 2.71 2.71 0 0 0-1.174.784 1.91 1.91 0 0 0-.45 1.287c-.01.37.076.736.25 1.063z"/>
                    </svg>
                    <!-- bigwavedevelopment</a> -->
                </div>
                <div class="modalText">
                    <h6>bigwavedevelopment</h6>
                </div>
              </div>
              <div class="svgText">
                      <div class="modalsvg">
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" fill="#D33E32" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/></svg>
                      </div>
                      <div class="modalText">
                          <!-- <h6>david@bigwavedevelopment.com</h6> -->
                          <h5>info@bigwavedevelopment.com</h5>
                      </div>
              </div>

              <div class="icon-column">
                <a href="https://www.facebook.com/bigwavedevelopmentagency" class="btn">
                  <img src="frontend/images/icon/facebook.svg" alt="">
                </a>
                <a href="https://twitter.com/BWD2008" class="btn">
                  <img src="frontend/images/icon/twi.svg" alt="">
                </a>
                <a href="https://www.linkedin.com/company/bigwavedevelopment/" class="btn">
                  <img src="frontend/images/icon/link.svg" alt="">
                </a>
                <!-- <a href="https://works-reddensoft.com/bwd-new/" class="btn">
                  <img src="frontend/images/icon/be.svg" alt="">
                </a> -->
              </div>
          </div>
          <div class="modalRight">
            <div class="mdImg imgNone mdTop">
              <img src="@/assets/images/one.png">
            </div>
            <div class="mdImge imgNone">
              <img src="@/assets/images/two.png">
            </div>
            <div class="mdImges imgNone mnone">
              <img src="@/assets/images/three.png">
            </div>
            
            <div class="modalRightInput">
              
                <h2>Get in touch</h2>
                <h4>Say hello,</h4>
                <form v-on:submit.prevent="submitForm2" ref="form" name="form">
                  <div class="mdreIn mdreInFlex">
                    <div class="mdreInFe">
                    <label>Name</label>
                    <input class="mdrinput" :class="{ filderror: v2$.name.$error }" type="text" placeholder="" v-model="state2.name">
                    <span class="error-span" v-if="v2$.name.$error">{{ v2$.name.$errors[0].$message }}</span>
                  </div>
                  <div class="mdreInFe ">
                    <label>Email <Address></Address></label>
                    <input class="mdrinput" :class="{ filderror: v2$.email.$error }" type="text" placeholder="" v-model="state2.email" style="width: 102%;">
                    <span class="error-span" v-if="v2$.email.$error">{{ v2$.email.$errors[0].$message }}</span>
                    <!-- <span class="error-span" v-if="v2$.email.$dirty && !v2$.email.$error">Please Enter Valid Email</span> -->
                    
                  </div>
                  </div>
                  <div class="mdreIn mdrelpad">
                    <label>Contact Number</label>
                    <input class="mdrinput" min="0" :class="{ filderror: v2$.phone.$error }" type="number" placeholder=""  v-model="state2.phone">
                    <span class="error-span" v-if="v2$.phone.$error">{{ v2$.phone.$errors[0].$message }}</span>
                  </div>
                  <div class="mdreIn mdrelpad">
                    <label>Message</label>
                    <textarea class="mdreText" :class="{ filderror: v2$.message.$error }" v-model="state2.message"></textarea>
                    <span class="error-span" v-if="v2$.message.$error">{{ v2$.message.$errors[0].$message }}</span>
                  </div>
                  <div class="mdreIn mdrBtnPad">
                    <button type="submit" class="mdreBtn" :disabled="buttonclick" style="cursor:pointer">
                    
                      <span class="error-btn" v-if="buttonclick==true" >Submitting. . .</span>
                      <span class="error-btn" v-else > Submit</span>
                    </button>
                    
                  </div>
              </form>
              <div class="main-error-msg" v-if="errormessage || successmessage" :class="errormessage ? '' : 'suce-area'">
                    <h5 :class="{show:errormessage}" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-x-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg> {{errormessage}}</h5> 
                    <h5 :class="{show:successmessage}" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-check-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                        </svg> {{successmessage}}</h5> 
                  </div>
              <div class="mdrPhone">
                <div class="svgText mdrpdTop">
                  <div class="modalsvg">
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" fill="#D33E32" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0  1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/></svg>
                  </div>
                  <div class="modalText">
                      <h6>+1(727) 300-6244</h6>
                  </div>
                  </div>
                  <div class="svgText">
                          <div class="modalsvg">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" fill="#D33E32" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/></svg>
                          </div>
                          <div class="modalText">
                              <h6>david@bigwavedevelopment.com</h6>
                              <h5>mike@bigwavedevelopment.com</h5>
                          </div>
                  </div>
              </div>
            </div>
            
            <div class="modelClose" >.
              <a href="#" v-on:click="execute()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg></a>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

import { defineComponent } from 'vue';
import { BASE_URL_API } from '../config'
import { reactive, computed } from "vue"
import useValidate from "@vuelidate/core"
import { required, email, minLength, maxLength } from "@vuelidate/validators"
import axios from 'axios'

export default defineComponent({
  name: 'PopUp',
  props:['callback'],
  setup() {
      const state2 = reactive({
          name: '',
          email: '',
          phone: '',
          message:'',
      })

      const rules2 = computed(() => {
          return {
              name: { required },
              email: { required, email },
              phone: { required, minLength: minLength(10), maxLength: maxLength(12) },
              message: { required },
          }
      })
      const v2$ = useValidate(rules2, state2)

      return {
          state2,
          v2$
      }
  },
  data() {
      return {
          form: {
              name: '',
              email: '',
              phone: '',
              message: ''
          },
          errormessage: '',
          successmessage: '',
          buttonclick:false,
      }
  },
  methods:{
    async submitForm2() {
        this.buttonclick = true;
        this.v2$.$validate()
        if (!this.v2$.$error) {
          //this.$router.push("/thank-you");
          await axios.post(BASE_URL_API+'get-in-touch.php',
            {
                name: this.state2.name,
                email: this.state2.email,
                content: this.state2.message,
                phone: this.state2.phone,
            })
            .then((response) => {
              console.log("DATA :",response);
                if (response.data.result == 'success') {
                    this.state2= {};
                    this.errormessage = '';
                    this.successmessage = response.data.messase;
                    this.buttonclick = false;
                    this.$router.push("/thank-you");
                } else {
                    this.successmessage = '';
                    this.errormessage = response.data.messase;
                    this.buttonclick = false;
                }

            })
            .catch(({ response }) => {
              this.error = response;
              console.log("DATA ERROR: ",this.error);
            });

          this.errormessage = '';
          this.successmessage = 'Thank you for your interest! Your message has been sent successfully';
          this.buttonclick = false;       
        } else {
            this.successmessage = '';
            console.log("ERROR: ",this.v2$.$error);

            this.errormessage = 'All fields are must be valid and required';

            this.buttonclick = false;
        }
        //console.log("OUT:",this.v$.firstName.$error)
        //console.log("End3");
        setTimeout(() => {
            this.successmessage = '';
            this.errormessage = '';
            console.log("HI");
        }, 10000);
    },
    execute() {
      if (this.callback) {
        //alert("hiiii In");
        this.callback()
      }
    }
  }
});

</script>
<style>

.mdreInFlex  {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mdreInFe  {
  width: 50%;
  padding: 0 7px;
}
.mdrelpad  {
  padding-left: 7px;
}

.mdrinput.filderror {
    border-color: #D43F33;
}
.mdreText.filderror {
    border-color: #D43F33;
}

.contact-section .form-itms{
    width: 100%;
}
.mdreIn .error-span{
  position: relative;
  top: 5px;
  font-size: 11px;
  color: #D43F33;
  background: #fff;
  top: -14px;
  left: 25px;
  padding: 4px;
}

.main-error-msg {
    background: #34a853;
    padding: 10px 20px;
    width: fit-content;
    margin: 20px auto 0;
    border-radius: 8px;
}
.main-error-msg h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    display: flex;
    align-items: center;
    color: #fff;
    column-gap: 10px;
    text-align: center;
}

.main-error-msg h5:not(.show){
  display: none;
}

.main-error-msg:not(.suce-area){
  background: #D43F33;
}
        *  {
            /* padding: 0;
            margin: 0; */
            box-sizing: border-box;
            font-family: 'Inter', sans-serif;
        }
        .modal {
          position: fixed;
          top: 50%;
          left: 50%;
          z-index: 1060;
          width: 100%;
          height: 100%;
          overflow-x: hidden;
          overflow-y: hidden;
          outline: 0;
          transform: translate(-50% , -50%);
          background: rgba(3, 3, 3, 0.6);
        }
        .modal-content {
	        position: relative;
	        width: 100%;
	        max-width: 1093px;
	        background-color: #fff;
	        border-radius: 20px;
	        position: relative;
	        top: 50%;
	        left: 50%;
	        transform: translate(-50% , -50%);
        }
        .modal-body  {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          height: 600px;
          overflow: hidden;
        }
        .modalLeft  {
          width: 393px;
          background: #FFFFFF;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.18);
          border-radius: 20px;
          padding: 50px;
          height: 100%;
          position: relative;
        }
        .modalRight  {
          width: calc(100% - 393px);
          padding: 40px 60px 40px 75px;
          position: relative;
        }
        .modalLeft h2  {
          font-weight: 700;
          font-size: 49px;
          line-height: 72px;
          color: #000000;
          padding-bottom: 82px;
        }
        .modalRightInput h2  {
          font-weight: 700;
          font-size: 49px;
          line-height: 72px;
          color: #000000;
          padding-bottom: 40px;
          display: none;
        }
        .svgText  {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-bottom: 30px;
        }
        .modalsvg  {
          width: 48px;
        }
        .modalText  {
          width: calc(100% - 48px);
        }
        .modalText h6  {
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
          color: #353535;
          word-break: break-all;
        }
        .modalText h5  {
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
          color: #353535;
          padding-top: 10px;
          word-break: break-all;
        }
        .modalRightInput h4  {
          font-weight: 500;
          font-size: 43px;
          line-height: 72px;
          text-transform: capitalize;
          color: #000000;
          padding-bottom: 5px;
        }
        .mdreIn  {
          padding-bottom: 5px;
        }
        .mdreIn label  {
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #666666;
            padding-bottom: 2px;
        }
        .mdrinput  {
            
            width:100%;
            /* background: #000000; */
            border: 1px solid #696969;
            border-radius: 8px;
            height: 48px;
            flex-grow: 1;
            padding-left: 24px;
            font-weight: 400;
            font-size: 16px;
            color: #212529;
        }
        .mdreText  {
            width:100%;
            resize: none;
            border: 1px solid #696969;
            border-radius: 8px;
            height: 80px;
            flex-grow: 1;
            padding: 10px 24px;
            font-weight: 400;
            font-size: 16px;
            color: #212529;
        }
        .mdreBtn  {
            width: 275px;
            height: 55px;
            background: #EA4335;
            border-radius: 100px;
            font-weight: 500;
            font-size: 22px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
        }
        .mdImg  {
          position: absolute;
          top: 0;
          right: 0;
        }
        .mdImge  {
          position: absolute;
          top: 120px;
          left: 10px; 
        }
        .mdImges  {
          position: absolute;
          bottom: 5px;
          right: 0;
        }
        .mdTop   {
          top: -26px;
          right: -31px;
        }
        .mdImg,
        .mdImge,
        .mdImges  {
          display: block;
        }
        .icon-column  {
          display: flex;
          padding-top: 50px;
          justify-content: center;
        }
        .icon-column .btn  {
          background: #EA4335;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          border-radius: 50px;
          padding: 0;
        }
        .icon-column .btn img  {
          display: block;
        }
        .modelClose  {
          position: absolute;
          top: -12px;
          right: 7px;
        }
        .modelClose a  {
          width: 40px;
          height: 40px;
          background: #fff;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.18);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
        }
        .imgNone  {
          display: none;
        }
        .mdrPhone {
          border-top: 1px dashed #ccc;
          margin-top: 20px;
          display: none;
        }
        .mdrpdTop  {
          padding-top: 30px;
        }
        .mdreIn.mdrBtnPad {
          padding-top: 19px;
        }

        @media (max-width:991px)  {
          .modalLeft {
            display: none;
          }
          .modal-body  {
            flex-direction: column-reverse;
          }
          .modalLeft,
          .modalRight  {
            width: 100%;
          }
          .modal-content {
	        position: relative;
	        width: 100%;
	        max-width: 1093px;
	        background-color: #fff;
	        border-radius: 20px;
	        position: relative;
	        top: 0%;
	        left: 0%;
	        transform: none;
        }
        .modal-body  {
            height: 100%;
        }
        .modelClose {
          position: absolute;
          top: 7px;
          right: 7px;
        }
        .modalRight {
          padding: 50px 30px 66px 30px;
          position: relative;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.18);
          border-radius: 20px;
          height: 100vh;
          overflow-x: hidden;
          overflow-y: scroll;
          margin: -15px 0;
        }
        .modalLeft h2 {
          font-size: 40px;
        }
        .modalRightInput h4  {
          display: none;
        }
        .modalRightInput h2  {
          display: block;
          font-weight: 700;
          font-size: 36px;
          padding-bottom: 30px;
        }
        .imgNone  {
          display: block;
        }
        .mdrPhone {
          display: block;
        }
        /* .modalRight  {
          overflow: hidden;
        } */
        .mdreBtn  {
          margin: 0 auto;
        }
        .modal {
          padding: 15px;
          height: auto;
        }
      .mdreBtn {
      width: 180px;
      height: 36px;
      font-size: 16px;
    }
    .mnone  {
      display: none;
    }
    }

    </style>
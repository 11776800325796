<template>
            
                         <div class="tabThree">
                            <div class="row">
                                <div class="col-lg-4 cardPadding">
                                    <div class="card-item">
                                        <img src="frontend/images/blog/blog7.png" alt="">
                                        <div class="atle-text">
                                          <div class="date">
                                            <img src="frontend/images/blognews/Calendar-icon.svg" alt="">
                                            <p>April 12, 2022</p>
                                          </div>
                                          <ul>
                                              <li><a class="headline" href="#">
                                                  <h1>Strategize Your Email Marketing Through Us</h1>
                                              </a></li>
                                              <li>
                                                <router-link to ="/blogdetails">
                                                <a href="#" class="btn">Read More</a>
                                                </router-link>
                                                </li>
                                          </ul>
                                        </div>
                                      </div>
                                 </div>
                                 <div class="col-lg-4 cardPadding">
                                    <div class="card-item">
                                        <img src="frontend/images/blog/blog8.png" alt="">
                                        <div class="atle-text">
                                          <div class="date">
                                            <img src="frontend/images/blognews/Calendar-icon.svg" alt="">
                                            <p>April 12, 2022</p>
                                          </div>
                                          <ul>
                                            <li><a class="headline" href="#">
                                                <h1>Amplify Business Through Social Media Marketing</h1>
                                            </a></li>
                                            <li>
                                              <router-link to ="/blogdetails">
                                              <a href="#" class="btn">Read More</a>
                                              </router-link>
                                              </li>
                                        </ul>
                                        </div>
                                      </div>
                                 </div>
                                 <div class="col-lg-4 cardPadding">
                                    <div class="card-item">
                                        <img src="frontend/images/blog/blog9.png" alt="">
                                        <div class="atle-text">
                                          <div class="date">
                                            <img src="frontend/images/blognews/Calendar-icon.svg" alt="">
                                            <p>April 12, 2022</p>
                                          </div>
                                          <ul>
                                            <li><a class="headline" href="#">
                                                <h1>Develop Unique Web Development Service to Optimoze Performance</h1>
                                            </a></li>
                                            <li>
                                              <router-link to ="/blogdetails">
                                              <a href="#" class="btn">Read More</a>
                                              </router-link>
                                              </li>
                                        </ul>
                                        </div>
                                      </div>
                                 </div>
                             </div>
                         </div>
</template>

<style scoped>
  .tabThree{
      padding-top: 70px;
  }
  .cardPadding {
    padding: 28px;
    }
</style>

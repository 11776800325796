

import MetaTag from '../components/MetaTag.vue'
import FooTer from '../views/FooTer.vue'
import NavBar from '../views/NavBar.vue'
import Banner from '../components/Banner.vue'
import BrandForefront from '../components/BrandForefront.vue'
BrandForefront
import StayConectedWith from '../components/StayConectedWith.vue'
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'UiUxStrategy',
  components: {
    NavBar,
    FooTer,
    StayConectedWith,
    Banner,
    BrandForefront,
    MetaTag
  },
  data: () => ({
    title:'With best-in-class <strong>UI/UX DESIGN</strong> from our end, you can outsmart the competitors',
    description:'<ul><li><span>1.</span> Our experts are among the finest in the industry, having a track record of creating profitable businesses. They combine performance with great customer service.</li><br><li><span>2.</span> Enhance your company with a dependable UI/UX Design that your consumers will enjoy. Our award-winning website designers will make the greatest possible representation of your brand.</li> <br><li><span>3.</span> We work with you after an intrinsic landscape study of your competitor.</li></ul>',
    defineComponentImage: 'uiux-with-best-in-class.png',
    metaTitle:"Creative  UI/UX Design | Big Wave Development LLC",
    metaDescription:"Our visual designers at Big Wave Development create UI/UX strategy for digital experiences with the latest frameworks to take your website to the next level."
  
  }),
});


import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


import VueGtag from "vue-gtag";


createApp(App).use(store)
            .use(router)
            .use(VueGtag, {
                config: { id: "AW-10925082080" }
            })
            .mount('#app');
